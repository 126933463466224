





































































































import { Component, Vue } from 'vue-property-decorator';
import { IUserProfileCreate, IUserService } from '@/interfaces';
import { dispatchGetUsers, dispatchCreateUser, dispatchGetServices, readServices } from '@/store/admin/accessors';
import VTextFieldWithValidation from '@/components/VTextFieldWithValidation.vue';
import { ValidationProvider } from 'vee-validate';

@Component({
  components: {
    VTextFieldWithValidation,
  },
})
export default class CreateUser extends Vue {
  public login = '';
  public valid = false;
  public fullName: string = '';
  public email: string = '';
  public isActive: boolean = true;
  public isSuperuser: boolean = false;
  public password1: string = '';
  public password2: string = '';
  public services: IUserService[] = [];
  public servicesList: string[] = [];
  public currentService: string = '';
  public servicesHeaders = [
    {
      value: 'service',
      width: '15%',
    },
    {
      value: 'allowed_senders',
      width: '25%',
    },
    {
      value: 'callback_url',
      width: '25%',
    },
    {
      value: 'callback_token',
      width: '15%',
    },
    {
      value: 'callback_token_header',
      width: '15%',
    },
    {
      value: 'action',
      width: '5%',
    },
  ];
  public $refs!: {
    observer: InstanceType<typeof ValidationProvider>;
  };

  public async mounted() {
    await dispatchGetUsers(this.$store);
    await dispatchGetServices(this.$store);
    this.reset();
  }

  public reset() {
    this.servicesList = Array.from(readServices(this.$store), (x) => x.name);
    this.login = '';
    this.password1 = '';
    this.password2 = '';
    this.fullName = '';
    this.email = '';
    this.isActive = true;
    this.isSuperuser = false;
    this.services = [];
    this.$refs.observer.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public deleteUserService(item) {
    const index = this.services.indexOf(item);
    this.services.splice(index, 1);
  }

  public addUserService() {
    if (this.currentService !== '') {
      this.services.push({
        service: this.currentService,
        check_sender: true,
        allowed_senders: [],
        callback_url: null,
        callback_token: null,
        callback_token_header: null,
      });
      this.servicesList.splice(this.servicesList.indexOf(this.currentService), 1);
      this.currentService = '';
    }
  }

  public async submit() {
    if (await this.$refs.observer.validate()) {
      const updatedProfile: IUserProfileCreate = {
        login: this.login,
      };
      for (const service of this.services) {
        if (!(service.allowed_senders instanceof Array)) {
          service.allowed_senders = service.allowed_senders.split(',').map((item) => item.trim());
        }
      }
      updatedProfile.services = this.services;
      updatedProfile.is_active = this.isActive;
      updatedProfile.is_superuser = this.isSuperuser;
      updatedProfile.password = this.password1;
      const result = await dispatchCreateUser(this.$store, updatedProfile);
      if (result) {
        this.$router.push('/main/admin/users');
      }
    }
  }
}
